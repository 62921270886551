<script>
	import parsePhone from 'libphonenumber-js';
	import { TelInput, normalizedCountries } from 'svelte-tel-input';

	let {
		disabled = false,
		detailedValue = $bindable(),
		required = false,
		name = 'phone',
		class: className = '',
		country = $bindable(),
		value = $bindable(),
		valid = $bindable(),
		options = {
			spaces: true,
			autoplaceholder: true,
			format: 'national',
		},
		phoneCountry = null,
		phoneNational = null,
	} = $props();

	let number = value ? parsePhone(value) : null;
	if (value) {
		phoneCountry = number?.country;
		phoneNational = number
			?.formatNational()
			?.replace(/[()]/g, '')
			?.replace(/[-]/g, ' ')
			?.trim();
	}

	country = country ?? phoneCountry ?? null;
	value = phoneNational ?? value ?? null;
</script>

<div class={['flex rounded-lg', className ?? ''].join(' ')}>
	<select
		class="form-select m-0 block max-w-32 cursor-pointer appearance-none rounded-l-lg border border-indow-grey-light bg-clip-padding bg-no-repeat text-base font-normal text-indow-grey-dark focus:outline-none
    "
		class:max-w-40={country === null}
		autocomplete="tel-country-code"
		aria-label="Default select example"
		name="countryCode"
		bind:value={country}
	>
		<option value={null} hidden={country !== null}>Please select</option>
		<option
			value="US"
			selected={'US' === country}
			aria-selected={'US' === country}
		>
			US (+1)
		</option>
		<option
			value="CA"
			selected={'CA' === country}
			aria-selected={'CA' === country}
		>
			CA (+1)
		</option>
		{#each normalizedCountries as currentCountry (currentCountry.id)}
			<option
				value={currentCountry.iso2}
				selected={currentCountry.iso2 === country}
				aria-selected={currentCountry.iso2 === country}
			>
				{currentCountry.iso2} (+{currentCountry.dialCode})
			</option>
		{/each}
	</select>

	<TelInput
		bind:options
		bind:value
		bind:country
		bind:detailedValue
		bind:required
		bind:disabled
		bind:valid
		{name}
		class="w-full rounded-r-lg bg-indow-white px-4 py-1 
        text-indow-grey-dark focus:outline-none {valid
			? 'border border-indow-grey-light border-l-indow-grey-light'
			: 'border-2 border-red-600'}"
		autocomplete="tel-national"
	/>
</div>
